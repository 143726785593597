import Link from 'next/link'
import { type FC } from 'react'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { PostCard } from '@lifedot/components/PostCard'
import { css } from '@emotion/react'
import { router } from '@lifedot/router'
import { mq } from '@lifedot/styles/mediaQuery'
import { PostList_Fragment$key } from './__generated__/PostList_Fragment.graphql'

const styles = {
  layout: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 12,
    [mq('sp')]: {
      gridTemplateColumns: '1fr',
      gap: 8,
      padding: '0 12px'
    }
  }),
  anchor: css({
    textDecoration: 'none',
    display: 'flex'
  }),
  heading: css({
    marginBottom: 4,
    [mq('sp')]: {
      padding: '0 12px'
    }
  })
}

type PostListProps = {
  posts: PostList_Fragment$key
}

export const PostList: FC<PostListProps> = ({ posts }) => {
  const { items } = useFragment(fragment, posts)

  return (
    <div css={styles.layout}>
      {items.map(({ h1, slug, image }) => (
        <Link href={router.post(slug)} passHref prefetch={false} key={slug}>
          <a css={styles.anchor}>
            <PostCard
              title={h1}
              thumbnailUrl={image?.url}
              thumbnailAlt={image?.alt}
              horizontal
            />
          </a>
        </Link>
      ))}
    </div>
  )
}

const fragment = graphql`
  fragment PostList_Fragment on PostResult {
    items {
      h1
      slug
      image {
        url
        alt
      }
    }
  }
`
