/**
 * @generated SignedSource<<38e17fbf04e957dd28f12fce21d3cdf4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type PostList_Fragment$data = {
  readonly items: ReadonlyArray<{
    readonly h1: string
    readonly slug: string
    readonly image: {
      readonly url: string
      readonly alt: string | null
    } | null
  }>
  readonly ' $fragmentType': 'PostList_Fragment'
}
export type PostList_Fragment$key = {
  readonly ' $data'?: PostList_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'PostList_Fragment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'PostList_Fragment',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'Post',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'h1',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'slug',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          concreteType: 'PostImage',
          kind: 'LinkedField',
          name: 'image',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'url',
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'alt',
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'PostResult',
  abstractKey: null
}

;(node as any).hash = 'e355d8bcf9c7a9e9f57286f5e73f4767'

export default node
